import LineChart from "../components/LineChart";
import React from "react";

function Home() {
  return (
    <main className="flex-grow flex flex-col items-center justify-center text-center px-6 py-12 md:py-20">
      <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
        Welcome to Ethiopian Black Market Ex
      </h2>
      <p className="text-lg md:text-xg mb-2">
        We provide real-time and historical data on the Ethiopian black market
        USD/ETB exchange rate.
        <br />
        Trusted and 100% reliable source
      </p>
      <div className="w-full flex justify-center mb-16">
        <LineChart />
      </div>
      <table class="min-w-full table-auto text-white mt-4 mb-16">
        <thead>
          <tr>
            <th class="px-4 py-2 border-b">USD</th>
            <th class="px-4 py-2 border-b">ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-4 py-2">1 USD</td>
            <td class="px-4 py-2">121.95 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">5 USD</td>
            <td class="px-4 py-2">609.75 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">10 USD</td>
            <td class="px-4 py-2">1095.72 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">25 USD</td>
            <td class="px-4 py-2">1219.5 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">50 USD</td>
            <td class="px-4 py-2">6097.5 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">75 USD</td>
            <td class="px-4 py-2">9146.25 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">100 USD</td>
            <td class="px-4 py-2">12195 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2">
        Ethiopian black market rate in different countries around the world
      </p>
      <table class="min-w-full table-auto text-white mt-4 mb-16">
        <thead>
          <tr>
            <th class="px-4 py-2 border-b">Country</th>
            <th class="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-4 py-2">1 USD in United States of America</td>
            <td class="px-4 py-2">121.95 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 GBP in United Kingdom (England)</td>
            <td class="px-4 py-2">158.35 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 EUR in Italy</td>
            <td class="px-4 py-2">133.75 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 EUR in Germany</td>
            <td class="px-4 py-2">134.10 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 EUR in Sweden</td>
            <td class="px-4 py-2">133.70 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 SEK in Saudi Arabia</td>
            <td class="px-4 py-2">11.68 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 AED in UAE</td>
            <td class="px-4 py-2">32.83 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 ILS in China</td>
            <td class="px-4 py-2">16.99 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2">
        Compare today's Euro to Ethiopian Birr (EUR to ETB) exchange rates with
        remittance service providers to see the difference in fees and exchange
        rates.
      </p>
      <table class="min-w-full table-auto text-white mt-4 mb-16">
        <thead>
          <tr>
            <th class="px-4 py-2 border-b">1 EUR</th>
            <th class="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-4 py-2">Revolut (no fee)</td>
            <td class="px-4 py-2">121.9507 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Dahabshiil (no fee)</td>
            <td class="px-4 py-2">130.8991 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Western Union</td>
            <td class="px-4 py-2">121.8534 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Talke Remit</td>
            <td class="px-4 py-2">130.8991 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Cambridge Currencies</td>
            <td class="px-4 py-2">121.9288 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2">
        Compare today's US Dollar to Ethiopian Birr (USD to ETB) exchange rates
        with remittance service providers to see the difference in fees and
        exchange rates.
      </p>
      <table class="min-w-full table-auto text-white mt-4 mb-16">
        <thead>
          <tr>
            <th class="px-4 py-2 border-b">1 USD</th>
            <th class="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-4 py-2">Cash Go (no fee)</td>
            <td class="px-4 py-2">125.8901 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Western Union</td>
            <td class="px-4 py-2">109.6494 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Ria money transfer</td>
            <td class="px-4 py-2">106.5200 ETB</td>
          </tr>
          <tr>
            <td class="px-4 py-2">Cambridge Currencies</td>
            <td class="px-4 py-2">113.1075 ETB</td>
          </tr>
        </tbody>
      </table>
      <p className="text-lg md:text-xg mb-2">
        Eritrean Nakfa exchange rate from difference currencies
      </p>
      <table class="min-w-full table-auto text-white mt-4">
        <thead>
          <tr>
            <th class="px-4 py-2 border-b">1 Nakfa</th>
            <th class="px-4 py-2 border-b">Rate in ETB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="px-4 py-2">1 USD</td>
            <td class="px-4 py-2">15.00 ERN</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 GBP</td>
            <td class="px-4 py-2">20.00 ERN</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 EUR</td>
            <td class="px-4 py-2">17.00 ERN</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 SAR</td>
            <td class="px-4 py-2">4.00 ERN</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 SEK</td>
            <td class="px-4 py-2">1.50 ERN</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 AED</td>
            <td class="px-4 py-2">4.00 ERN</td>
          </tr>
          <tr>
            <td class="px-4 py-2">1 ILS</td>
            <td class="px-4 py-2">2.00 ERN</td>
          </tr>
        </tbody>
      </table>
    </main>
  );
}

export default Home;
