import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { Chart, Line } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ExchangeTable = () => {
  const [banks, setBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(1);
  const [rates, setRates] = useState([]);

  useEffect(() => {
    // Fetch the list of banks
    axios
      .get("https://ethiopianblackmarket.com/api/Bank/get")
      .then((response) => setBanks(response.data))
      .catch((error) => console.error("Error fetching banks:", error));
  }, []);

  useEffect(() => {
    if (selectedBankId) {
      // Fetch the exchange rates for the selected bank
      axios
        .get(
          `https://ethiopianblackmarket.com/api/BankRate/get/${selectedBankId}`
        )
        .then((response) => setRates(response.data))
        .catch((error) =>
          console.error("Error fetching exchange rates:", error)
        );
    }
  }, [selectedBankId]);

  // Prepare data for the chart
  const chartData = {
    labels: rates.map((rate) => new Date(rate.date)),
    datasets: [
      {
        label: "Selling Rate",
        data: rates.map((rate) => rate.sellingRate),
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
      {
        label: "Buying Rate",
        data: rates.map((rate) => rate.buyingRate),
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "USD/ETB Exchange Rate Trends",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "DD MMM YYYY",
        },
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Rate",
        },
      },
    },
  };

  return (
    <div className="container mx-auto mt-8">
      <div className="mb-4">
        <label
          htmlFor="bankSelect"
          className="block text-lg font-medium text-white"
        >
          Select a Bank:
        </label>
        <div className="relative inline-block w-64">
          <select
            id="bankSelect"
            className="block appearance-none w-full bg-gray-400 border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none"
            value={selectedBankId || ""}
            onChange={(e) => setSelectedBankId(e.target.value)}
          >
            <option value="" disabled>
              Select a bank
            </option>
            {banks.map((bank) => (
              <option key={bank.id} value={bank.id}>
                {bank.name.toUpperCase()}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <FontAwesomeIcon icon={faSortDown} />
          </div>
        </div>
      </div>

      {selectedBankId && rates.length > 0 && (
        <div className="bg-white px-2">
          <Line data={chartData} options={chartOptions} />
        </div>
      )}

      {selectedBankId && rates.length > 0 ? (
        <table className="min-w-full bg-white shadow-md rounded mt-4">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Currency
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Selling Rate
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Buying Rate
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {rates
              .slice(-3) // Get the last 3 rates
              .map((rate) => (
                <tr key={rate.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {rate.currency}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600">
                    {rate.sellingRate.toFixed(4)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-red-600">
                    {rate.buyingRate.toFixed(4)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(rate.date).toLocaleDateString()}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="text-gray-500 mt-4">
          No exchange rates available. Please select a bank.
        </div>
      )}
    </div>
  );
};

export default ExchangeTable;
