import { Link } from "react-router-dom";
import { useState } from "react";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="w-full py-4 shadow mb-4">
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center md:justify-start">
          <Link
            to="/"
            className="text-xl font-extrabold md:order-1 md:w-full text-left"
          >
            Ethiopian Black Market Exchange
          </Link>
          <div className="md:hidden md:order-3">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-3xl focus:outline-none"
            >
              &#9776;
            </button>
          </div>
        </div>
        <nav
          className={`${
            isMenuOpen ? "block" : "hidden"
          } md:flex md:justify-center space-y-2 md:space-y-0 md:space-x-6 mt-4`}
        >
          <Link
            to="/"
            className="block py-2 md:py-0 px-4 hover:text-indigo-300"
            onClick={() => setIsMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to="cryptocurrency"
            className="block py-2 md:py-0 px-4 hover:text-indigo-300"
            onClick={() => setIsMenuOpen(false)}
          >
            Cryptocurrency Exchanges Rate
          </Link>
          <Link
            to="exchangeRate"
            className="block py-2 md:py-0 px-4 hover:text-indigo-300"
            onClick={() => setIsMenuOpen(false)}
          >
            Bank Exchange Rate
          </Link>
          <Link
            to="about"
            className="block py-2 md:py-0 px-4 hover:text-indigo-300"
            onClick={() => setIsMenuOpen(false)}
          >
            About
          </Link>
          <Link
            to="contact"
            className="block py-2 md:py-0 px-4 hover:text-indigo-300"
            onClick={() => setIsMenuOpen(false)}
          >
            Contact Us
          </Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
