import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { DateTime } from "luxon";
import "chartjs-adapter-luxon";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "USD/ETB Exchange Rate",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
        pointRadius: 4,
        data: [],
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let date = DateTime.fromISO(context.raw.date).toLocaleString(
              DateTime.DATETIME_MED
            );
            return `${date}: ${context.raw.rate} ETB`;
          },
        },
      },
      legend: {
        labels: {
          font: {
            size: 12,
            family: "Arial",
          },
          color: "#555",
        },
      },
      title: {
        display: true,
        text: "USD/ETB Exchange Rate",
        font: {
          size: 14,
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          tooltipFormat: "HH:mm",
          displayFormats: {
            minute: "HH:mm",
          },
        },
        title: {
          display: true,
          text: "Time",
          font: {
            size: 12,
          },
          color: "#555",
        },
        ticks: {
          color: "#777",
        },
      },
      y: {
        title: {
          display: true,
          text: "Rate (USD/ETB)",
          font: {
            size: 12,
          },
          color: "#555",
        },
        ticks: {
          color: "#777",
        },
      },
    },
    animation: {
      duration: 1000,
      easing: "easeInOutQuart",
    },
  });

  useEffect(() => {
    const fetchData = () => {
      fetch(
        "https://ethiopianblackmarket.com/api/Proxy/convert?pageNumber=1&pageSize=50&searchQuery=USDT"
      )
        .then((response) => response.json())
        .then((data) => {
          const formattedData = data.items
            .map((item) => ({
              x: DateTime.fromISO(item.date).toJSDate(),
              y: item.rate,
              date: item.date,
              rate: item.rate,
            }))
            .reverse(); // Reverse the order of the data

          setChartData({
            labels: formattedData.map((item) => item.x),
            datasets: [
              {
                label: "USD/ETB Exchange Rate",
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 2,
                pointBackgroundColor: "rgb(255, 99, 132)",
                pointBorderColor: "#fff",
                pointBorderWidth: 2,
                pointRadius: 5,
                data: formattedData,
              },
            ],
          });
        })
        .catch((error) => console.error("Error fetching data:", error));
    };

    // Fetch data initially
    fetchData();

    // Set interval to fetch data every 2 minutes (120000 milliseconds)
    const intervalId = setInterval(fetchData, 120000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full max-w-4xl h-96 md:h-128">
      <div className="flex justify-left mb-8">
        <p className="text-lg font-medium">
          Current rate of exchange:{" "}
          {chartData.datasets[0].data.length > 0
            ? chartData.datasets[0].data[chartData.datasets[0].data.length - 1]
                .rate + " ETB"
            : "Loading..."}
        </p>
        <div className="w-4 h-4 bg-red-500 rounded-full animate-ping ml-2 mt-1"></div>
      </div>
      <div className="h-96 md:h-128 bg-gray-100 p-6 shadow-lg border border-gray-300">
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default LineChart;
