import LineChartBTC from "../components/LineChartBTC";

function Cryptocurrency() {
  return (
    <main className="flex-grow flex flex-col items-center justify-center text-center px-6 py-12 md:py-20">
      <p className="text-lg md:text-xg mb-2">
        Bitcoin (BTC) to Ethiopian Birr (ETB) exchange rate.
      </p>
      <div className="w-full flex justify-center">
        <LineChartBTC />
      </div>
    </main>
  );
}

export default Cryptocurrency;
