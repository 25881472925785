import React from "react";
import Header from "../views/Header";
import Footer from "../views/Footer";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-500 to-indigo-800 flex flex-col items-center text-white">
      <Header />
      <main>
        <Outlet /> {/* This will render the current route's component */}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
