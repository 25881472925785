import React from "react";
import ExchangeTable from "../components/ExchangeTable";
function ExchangeRate() {
  return (
    <div>
      <p className="text-base md:text-base">
        <ExchangeTable />
      </p>
    </div>
  );
}

export default ExchangeRate;
